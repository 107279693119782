import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class ActivityMappingService {
  MESSAGE_SENT_ACTIVITY = 1;
  SENT_INVITATION_WITH_MESSAGE_ACTIVITY = 2;
  ACCEPTED_CONNECTION_ACTIVITY = 3;
  RESOLVED_CONNECTION_ACTIVITY = 4;
  PIPA_VIEWED_PROFILE_ACTIVITY = 5;
  MESSAGE_RECEIVED_ACTIVITY = 7;
  SENTIMENT_CHANGE_ACTIVITY = 8;
  USER_MARKED_AS_ACTIVE_ACTIVITY = 15;
  TRANSFER_TO_NEW_CAMPAIGN_ACTIVITY = 16;
  SCHEDULED_FOLLOW_UP_ACTIVITY = 17;
  SENTIMENT_SCORE_CREATED_ACTIVITY = 18;
  SENTIMENT_SCORE_CHANGE_ACTIVITY = 19;

  constructor() { }

  isActivityMappedById(activity: any): boolean {
    return (
      activity.activity_type_id === this.ACCEPTED_CONNECTION_ACTIVITY ||
      activity.activity_type_id === this.MESSAGE_RECEIVED_ACTIVITY ||
      activity.activity_type_id === this.PIPA_VIEWED_PROFILE_ACTIVITY ||
      activity.activity_type_id ===
      this.SENT_INVITATION_WITH_MESSAGE_ACTIVITY ||
      activity.activity_type_id === this.USER_MARKED_AS_ACTIVE_ACTIVITY ||
      activity.activity_type_id === this.TRANSFER_TO_NEW_CAMPAIGN_ACTIVITY
    );
  }

  isPredefinedMessage = (a) =>
    a.activity_type_id === this.RESOLVED_CONNECTION_ACTIVITY;

  resolveActivityMappingById(activity: any, name?: string): any {
    name = name.split(' ')[0] || name || 'Prospect';
    let config;
    switch (activity.activity_type_id) {
      case this.MESSAGE_SENT_ACTIVITY:
        config = {
          activityType: this.resolveActivityMessage(activity.activity_type_id),
          message: !!activity.details && activity.details,
          icon: 'assets/icons/chat-text.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.ACCEPTED_CONNECTION_ACTIVITY:
        config = {
          activityType: `${name} accepted your invitation to connect`,
          icon: 'assets/icons/link.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.PIPA_VIEWED_PROFILE_ACTIVITY:
        // config = {
        //   activityType: `pipa viewed ${name}'s profile`,
        //   icon: 'assets/images/pipa_headshot.svg',
        //   createdAt: activity.created_at,
        // };
        config;
        break;
      case this.SENT_INVITATION_WITH_MESSAGE_ACTIVITY:
        config;
        break;
      case this.MESSAGE_RECEIVED_ACTIVITY:
        config = {
          activityType: `${name} responded to your message`,
          message: activity.details, // Include details as message
          icon: 'assets/icons/chat-text-filled.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.USER_MARKED_AS_ACTIVE_ACTIVITY:
        config = {
          activityType: activity.details,
          icon: 'assets/icons/lightening-bolt.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.TRANSFER_TO_NEW_CAMPAIGN_ACTIVITY:
        config = {
          activityType: activity.details,
          icon: 'assets/icons/transfer-icon.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.SENTIMENT_SCORE_CREATED_ACTIVITY:
        config = {
          activityType: 'Sentiment score created',
          message: !!activity.details && activity.details,
          icon: 'assets/images/pipa_headshot.svg',
          createdAt: activity.created_at,
        };
        break;
      case this.SENTIMENT_SCORE_CHANGE_ACTIVITY:
        config = {
          activityType: `Sentiment score change`,
          message: !!activity.details && activity.details,
          icon: 'assets/images/pipa_headshot.svg',
          createdAt: activity.created_at,
        };
        break;
    }
    return config;
  }

  handleActivityMapping(activity: any): any {
    switch (activity.activity) {
      case 'message_sent':
        return {
          activityType: this.resolveActivityMessage(activity.activity),
          message: activity.details,
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      case 'message_after_connection':
        return {
          activityType: this.resolveActivityMessage(activity.activity),
          message: activity.details,
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      case 'message_before_connection':
        return {
          activityType: this.resolveActivityMessage(activity.activity),
          message: activity.details,
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      case 'invite_without_message':
        return {
          activityType: this.resolveActivityMessage(activity.activity),
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      case 'uninterested':
      case 'interested':
        return {
          activityType: `Sentiment changed to ${activity.activity.includes('un') ? 'Not Interested' : 'Interested'
            }`,
          icon: activity.activity.includes('un')
            ? 'assets/icons/sentiment-down-icon.svg'
            : 'assets/icons/smile-icon.svg',
          createdAt: activity.created_at,
        };
      case 'user:intervention':
        return {
          activityType: activity.details,
          message: activity.details,
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      case 'responded':
      case 'not_found':
      case 'stale:pending':
      case 'stale:connected':
      case 'connected:no_engagement':
      case 'archived':
        // Each of these cases could have unique handling similar to the above
        return {
          message: this.resolveActivityMessage(activity.activity),
          icon: this.resolveActivityIcon(activity.activity),
          createdAt: activity.created_at,
        };
      default:
        return;
    }
  }

  resolveActivityMessage(activityType: string, name?: string): string {
    name = name.split(' ')[0] || name || 'Prospect';
    switch (activityType) {
      case 'drip:first_drip':
        return `Sent first drip message to ${name}`;
      case 'drip:second_drip':
        return `Sent second drip message to ${name}`;
      case 'drip:final_drip':
        return `Sent final drip message to ${name}`;
      case 'message_sent':
        return `You sent a message to ${name}`;
      case 'message_before_connection':
        return 'Sent invitation to connect with a message';
      case 'invite_without_message':
        return 'Sent invitation to connect';
      case 'message_after_connection':
        return 'Sent message after connection';
      case 'uninterested':
        return 'Sentiment changed to Not Interested';
      case 'interested':
        return 'Sentiment changed to Interested';
      case 'responded':
        return `No more scheduled activities, connection marked as complete`;
      case 'user:intervention':
        return 'You marked this connection as complete';
      case 'not_found':
        return `Could not find ${name}'s profile, connection marked as complete`;
      case 'stale:pending':
        return `${name} did not accept your invitation to connect, connection marked as complete`;
      case 'stale:connected':
      case 'connected:no_engagement':
        return `${name} did not engage in conversation, connection marked as complete.`;
      case 'archived':
        return 'Drip campaign completed, prospect did not engage in conversation. Connection marked as complete';
      default:
        return;
    }
  }

  resolveActivityIcon(activityType: string): string {
    switch (activityType) {
      case 'message_sent':
      case 'drip:first_drip':
      case 'drip:second_drip':
      case 'drip:final_drip':
        return 'assets/icons/chat-text.svg';
      case 'message_before_connection':
      case 'invite_without_message':
        return 'assets/icons/send-check.svg';
      case 'message_after_connection':
        return 'assets/icons/chat-text.svg';
      case 'uninterested':
        return 'assets/icons/sentiment-down-icon.svg';
      case 'interested':
        return 'assets/icons/smile-icon.svg';
      case 'responded':
      case 'user:intervention':
      case 'not_found':
      case 'stale:pending':
      case 'stale:connected':
      case 'connected:no_engagement':
      case 'archived':
        return 'assets/icons/resolved-shield.svg';
      default:
        return 'assets/images/pipa_headshot.svg';
    }
  }
}
