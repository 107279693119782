<div ngbDropdown class="d-inline-block">
  <button class="dropdown-toggle" ngbDropdownToggle aria-expanded="false">
    {{ buttonLabel }}
  </button>
  <div ngbDropdownMenu>
    <button
      *ngFor="let item of menuItems"
      class="dropdown-item"
      (click)="onItemSelect(item)"
    >
      {{ item }}
    </button>
  </div>
</div>
