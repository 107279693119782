import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { userProfileFeatureKey } from '../store/user/user.reducer';
import * as UserActions from '../store/user/user.actions';
import * as fromRoot from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard {
  private isUserDetailsFetched = false; // Cache indicator

  constructor(private store: Store<fromRoot.AppState>, private router: Router) { }

  canActivate(): Observable<boolean> {
    if (this.isUserDetailsFetched) {
      // If cached, directly read from the store
      return this.store.select(userProfileFeatureKey).pipe(
        filter((userState) => !userState.isLoading),
        take(1),
        map((userState) => !!userState.subscription_active),
        tap((hasActiveSubscription) => {
          if (!hasActiveSubscription) {
            this.router.navigate(['/billing-plans']);
          }
        })
      );
    }

    // // First-time fetch and cache the result
    this.store.dispatch(UserActions.fetchUserDetailsStart());

    return this.store.select(userProfileFeatureKey).pipe(
      filter((userState) => !userState.isLoading),
      take(1),
      tap(() => {
        this.isUserDetailsFetched = true; // Cache result after first fetch
      }),
      map((userState) => !!userState.subscription_active),
      tap((hasActiveSubscription) => {
        if (!hasActiveSubscription) {
          this.router.navigate(['/billing-plans']);
        }
      }),
      catchError((error) => {
        console.error('SubscriptionGuard error:', error);
        this.router.navigate(['/billing-plans']);
        return of(false);
      })
    );
  }
}
