import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert/alert.component';
import { AppButtonComponent } from './button/button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { SwitchComponent } from './switch/switch.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { PlaceHolderDirective } from './directives/placeholder.directive';
import { SkeletonDirective } from './directives/skeleton.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ResizableTextAreaDirective } from './directives/textarea-autoresize.directive';
import { CardComponent } from './card/card.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { ViewWrapperComponent } from './view-wrapper/view-wrapper.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ModalComponent } from './modal/modal.component';
import { TextComponent } from './text/text.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MessageTemplateOptionsComponent } from './message-template-options/message-template-options.component';
import { PinMaskInputComponent } from './pin-mask-input/pin-mask-input.component';
import {
  CodeInputComponentConfigToken,
  CodeInputComponentConfig,
} from './pin-mask-input/pin-mask-input.interface';
import { SquaredBadgeComponent } from './squared-badge/squared-badge.component';
import { BadgeComponent } from './badge/badge.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { OrdinalDateTimePipe } from './pipes/ordinal-dat-time.pipe';
import { ChipComponent } from './chip/chip.component';
import { PipaLoadingComponent } from './pipa-loading/pipa-loading.component';
import { AccordianComponent } from './accordian/accordian.component';
import { SuccessAnimationComponent } from './success-animation/success-animation.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { BoxCellComponent } from './box-cell/box-cell.component';
import { ProfileCellComponent } from './profile-cell/profile-cell.component';
import { DetailItemComponent } from './profile-cell/detail-item/detail-item.component';
import { ModalSideBarComponent } from './modal-side-bar/modal-side-bar.component';
import { MessageTemplateListComponent } from './message-template-cell-list/message-template-list.component';
import { ProfileVerticalCardComponent } from './profile-vertical-card/profile-vertical-card.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { MenuComponent } from './menu/menu.component';
import { ActivityLogCellComponent } from '../shared/activity-log-cell/activity-log-cell.component';
import { SkeletonLoadingComponent } from './skeleton-loading/skeleton-loading.component';
import { ChoiceButtonGroupComponent } from './choice-button-group/choice-button-group.component';
import { DrawerComponent } from './drawer/drawer.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { SelectableProfileCellComponent } from './selectable-profile-cell/selectable-profile-cell.component';
import { MobileActionsComponent } from './mobile-actions/mobile-actions.component';
import { ActivityStatsNuggetComponent } from './activity-stats-nugget/activity-stats-nugget.component';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { MessageCellComponent } from './message-cell/message-cell.component';
import { ActivityStatusCellComponent } from './activity-status-cell/activity-status-cell.component';
import { PulsingIndicatorComponent } from './pulsing-indicator/pulsing-indicator.component';
import { DropdownToggleButtonComponent } from './dropdown-toggle-button/dropdown-toggle-button.component';

@NgModule({
  declarations: [
    AlertComponent,
    PlaceHolderDirective,
    SkeletonDirective,
    PhoneMaskDirective,
    ResizableTextAreaDirective,
    AppButtonComponent,
    DropdownComponent,
    InputFieldComponent,
    SwitchComponent,
    CardHeaderComponent,
    CardComponent,
    TextAreaComponent,
    ViewWrapperComponent,
    PageHeaderComponent,
    ModalComponent,
    TextComponent,
    MessageTemplateOptionsComponent,
    PinMaskInputComponent,
    SquaredBadgeComponent,
    BadgeComponent,
    ActionBarComponent,
    ProfileCardComponent,
    DateAgoPipe,
    OrdinalDateTimePipe,
    ChipComponent,
    PipaLoadingComponent,
    AccordianComponent,
    SuccessAnimationComponent,
    RadioInputComponent,
    BoxCellComponent,
    ProfileCellComponent,
    DetailItemComponent,
    ModalSideBarComponent,
    MessageTemplateListComponent,
    ProfileVerticalCardComponent,
    ProfileImageComponent,
    ActivityLogCellComponent,
    SkeletonLoadingComponent,
    DrawerComponent,
    AlertBannerComponent,
    ChoiceButtonGroupComponent,
    SelectableProfileCellComponent,
    MobileActionsComponent,
    ActivityStatsNuggetComponent,
    MessageCellComponent,
    ActivityStatusCellComponent,
    PulsingIndicatorComponent,
    DropdownToggleButtonComponent,
  ],
  imports: [CommonModule, TooltipComponent, ReactiveFormsModule, MenuComponent, DropdownSelectComponent, NgbDropdownModule],
  exports: [
    PlaceHolderDirective,
    SkeletonDirective,
    PhoneMaskDirective,
    ResizableTextAreaDirective,
    CommonModule,
    AppButtonComponent,
    DropdownComponent,
    InputFieldComponent,
    SwitchComponent,
    CardHeaderComponent,
    CardComponent,
    TextAreaComponent,
    ViewWrapperComponent,
    PageHeaderComponent,
    ModalComponent,
    TextComponent,
    MessageTemplateOptionsComponent,
    PinMaskInputComponent,
    SquaredBadgeComponent,
    BadgeComponent,
    ActionBarComponent,
    ProfileCardComponent,
    DateAgoPipe,
    OrdinalDateTimePipe,
    ChipComponent,
    PipaLoadingComponent,
    AccordianComponent,
    SuccessAnimationComponent,
    RadioInputComponent,
    BoxCellComponent,
    ProfileCellComponent,
    DetailItemComponent,
    ModalSideBarComponent,
    MessageTemplateListComponent,
    ProfileVerticalCardComponent,
    ProfileImageComponent,
    ActivityLogCellComponent,
    SkeletonLoadingComponent,
    DrawerComponent,
    AlertBannerComponent,
    ChoiceButtonGroupComponent,
    SelectableProfileCellComponent,
    MobileActionsComponent,
    ActivityStatsNuggetComponent,
    MessageCellComponent,
    ActivityStatusCellComponent,
    PulsingIndicatorComponent,
    DropdownToggleButtonComponent,
  ],
  // providers: [LoggingService]
})
export class SharedModule {
  static forRoot(
    config: CodeInputComponentConfig
  ): ModuleWithProviders<PinMaskInputComponent> {
    return {
      ngModule: PinMaskInputComponent,
      providers: [{ provide: CodeInputComponentConfigToken, useValue: config }],
    };
  }
}
