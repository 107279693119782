import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipa-loading',
  template: `
  <div class="flex__column--center pipa-loading-container">
    <img [src]="variant === 'primary' ? primaryLogo : inverseLogo" alt="loading" class="pipa-loading-img">
    <span *ngIf="loadingText" class="loading-text s4">{{loadingText}}</span>
</div>
  `,
  styleUrls: ['./pipa-loading.component.scss']
})
export class PipaLoadingComponent implements OnInit {
  @Input() isLoading?: boolean;
  @Input() loadingText?: string;
  @Input() variant?: 'primary' | 'inverse' = 'primary';
  inverseLogo = 'assets/images/pipa_transparent.png';
  primaryLogo = 'assets/images/pipa_headshot.png';

  constructor() { }

  ngOnInit(): void {
  }

}
