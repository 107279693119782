import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { campaignsFeatureKey } from '../campaigns/store/campaigns.reducer';
import { messageTemplatesFeatureKey } from '../message-templates/store/message-templates.reducer';
import { leadListFeatureKey } from '../store/lead-list/lead-list.reducer';
import * as CampaignsActions from '../campaigns/store/campaigns.actions';
import * as MessageTemplatesActions from '../message-templates/store/message-templates.actions';
import * as LeadListActions from '../store/lead-list/lead-list.actions';
import * as fromRoot from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class DashboardDataResolver {
  private dataLoaded = {
    campaigns: false,
    messageTemplates: false,
    leadLists: false,
  };

  constructor(private store: Store<fromRoot.AppState>) { }

  resolve(): Observable<any> {
    const actionsToDispatch = [];

    // Check campaigns
    if (!this.dataLoaded.campaigns) {
      this.dataLoaded.campaigns = true;
      actionsToDispatch.push(() => this.store.dispatch(CampaignsActions.getCampaigns()));
    }

    // Check message templates
    if (!this.dataLoaded.messageTemplates) {
      this.dataLoaded.messageTemplates = true;
      actionsToDispatch.push(() => this.store.dispatch(MessageTemplatesActions.getMessageTemplates()));
    }

    // Check lead lists
    if (!this.dataLoaded.leadLists) {
      this.dataLoaded.leadLists = true;
      actionsToDispatch.push(() => this.store.dispatch(LeadListActions.fetchLeadListsStart()));
    }

    // Dispatch all actions if needed
    actionsToDispatch.forEach((dispatchAction) => dispatchAction());

    // Combine all selectors into a single observable
    return combineLatest([
      this.store.select(campaignsFeatureKey).pipe(filter(state => !state.isLoading), take(1)),
      this.store.select(messageTemplatesFeatureKey).pipe(filter(state => !state.isLoading), take(1)),
      this.store.select(leadListFeatureKey).pipe(filter(state => !state.isLoading), take(1)),
    ]).pipe(
      switchMap(([campaignsState, messageTemplatesState, leadListsState]) => {
        return of({
          campaigns: campaignsState.campaigns || [],
          messageTemplates: messageTemplatesState.messageTemplates || [],
          leadLists: leadListsState.leadLists || [],
        });
      }),
      catchError((error) => {
        console.error('Error resolving dashboard data:', error);
        return of({
          campaigns: [],
          messageTemplates: [],
          leadLists: [],
        });
      })
    );
  }
}
