import { createReducer, on } from '@ngrx/store';
import * as LinkedinProfileActivityActions from './linkedin-profile-activity.actions';

export interface LinkedinProfileActivityState {
  interactedProfiles: any[];
  activeProfileIndex: number;
  processingIndex: number;
  isProfileActivityInProgress: boolean;
}

export const initialState: LinkedinProfileActivityState = {
  interactedProfiles: [],
  activeProfileIndex: 0,
  processingIndex: 0, // Reset processing index
  isProfileActivityInProgress: false,
};

export const linkedinProfileActivityReducer = createReducer(
  initialState,

  // Add interacted profile
  on(LinkedinProfileActivityActions.addInteractedProfile, (state, { profile }) => ({
    ...state,
    interactedProfiles: [...state.interactedProfiles, profile]
  })),

  // Update interacted profile
  on(LinkedinProfileActivityActions.updateInteractedProfile, (state, { index, updatedProfile }) => ({
    ...state,
    interactedProfiles: state.interactedProfiles.map((profile, i) =>
      i === index ? updatedProfile : profile
    )
  })),

  // Increment processing index
  on(LinkedinProfileActivityActions.incrementProcessingIndex, (state) => ({
    ...state,
    processingIndex: state.processingIndex + 1,
  })),

  // Set active profile
  on(LinkedinProfileActivityActions.setActiveProfile, (state, { index }) => ({
    ...state,
    activeProfileIndex: index
  })),

  // Clear interacted profiles
  on(LinkedinProfileActivityActions.clearInteractedProfiles, (state) => ({
    ...state,
    interactedProfiles: [],
    activeProfileIndex: 0,
    processingIndex: 0, // Reset processing index
    isProfileActivityInProgress: false,
  })),

  // Navigate to the next profile
  on(LinkedinProfileActivityActions.nextProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex < state.interactedProfiles.length - 1
      ? state.activeProfileIndex + 1
      : state.activeProfileIndex
  })),

  // Navigate to the previous profile
  on(LinkedinProfileActivityActions.previousProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex > 0
      ? state.activeProfileIndex - 1
      : state.activeProfileIndex
  })),

  // Start the connection invite process
  on(LinkedinProfileActivityActions.startLinkedinProfileActivityProcess, (state) => ({
    ...state,
    isProfileActivityInProgress: true
  })),

  // Stop the connection invite process
  on(LinkedinProfileActivityActions.stopLinkedinProfileActivityProcess, (state) => ({
    ...state,
    isProfileActivityInProgress: false
  })),

  // Complete the connection invite process
  on(LinkedinProfileActivityActions.completeLinkedinProfileActivityProcess, (state) => ({
    ...state,
    isProfileActivityInProgress: false
  }))
);

export const linkedinlinkedinConnectionRequestFeatureKey = 'linkedinProfileActivity';

export const linkedinProfileActivityFeatureState = (state: any) => state[linkedinlinkedinConnectionRequestFeatureKey];
