
export class ApiOperation {

  loading: boolean;
  success: boolean;
  error: any;
  storeKey: string;
  set: (newState: any) => void

  constructor(storeKey, set) {
    this.loading = false;
    this.success = false;
    this.error = null;
    this.storeKey = storeKey,
      this.set = set
  }

  genNewApiData() {
    return new ApiOperation(this.storeKey, this.set)
  }

  updateStore(newValues) {
    this.set({ [this.storeKey]: newValues })
  }

  // For below methods: cannot spread because it leaves off the methods

  startLoading(): ApiOperation {
    const out = this.genNewApiData()
    out.loading = true;
    this.updateStore(out)
    return out;
  }

  succeeded(): ApiOperation {
    const out = this.genNewApiData()
    out.success = true;
    this.updateStore(out)
    return out;
  }

  failed(error: any): ApiOperation {
    const out = this.genNewApiData()
    out.success = false
    out.error = error
    this.updateStore(out)
    return out;
  }

  reset(): ApiOperation { return this.genNewApiData() }
}
