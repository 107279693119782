<app-modal
  [visible]="isVisible"
  [usePadding]="false"
  [isDarkTheme]="theme === 'dark'"
>
  <div
    class="modal-wrapper flex__column"
    [ngClass]="{ 'dark-theme': theme === 'dark' }"
  >
    <div class="modal-header flex__center--space-between">
      <h5 class="modal-title">Daily connection limit</h5>
      <div class="flex__center">
        <app-button
          *ngIf="!isMobile"
          [disabled]="+totalCampaignsAllocatedPercentage > 1"
          (click)="onSaveClick()"
          [label]="'Save'"
          [class]="theme === 'dark' ? 'opaque' : 'primary'"
        >
        </app-button>
        <img
          (click)="onCloseModalClick()"
          class="_close-icon"
          [ngClass]="{ 'dark-theme': theme === 'dark' }"
          [src]="
            theme === 'dark'
              ? 'assets/icons/x-close-white.svg'
              : 'assets/icons/x-close-black.svg'
          "
          alt="X"
          draggable="false"
        />
      </div>
    </div>
    <div class="help-content">
      <span class="daily-limit-prompt help-block"
        >Assign a weight for each campaign</span
      >
      <app-tooltip
        [content]="dailyLimitTooltip"
        [id]="'dailyLimitTooltip'"
        [tooltipPosition]="'right'"
      >
        <img
          class="info-icon daily-limit-info-icon"
          src="assets/icons/info.svg"
          alt="tooltip"
          draggable="false"
          style="margin-left: 0.5rem; width: 16px"
        />
      </app-tooltip>
    </div>
    <div
      class="flex__row--space-between campaign-dropdown-wrapper"
      *ngFor="let campaign of campaigns"
    >
      <div class="flex__row--space-between campaign-switch-wrapper">
        <span class="campaign-nickname">{{ campaign.campaign_nickname }}</span>
        <app-switch
          id="campaign-switch"
          title="Toggle campaign on/off"
          (click)="onToggleCampaign($event, campaign.id, !campaign.enabled)"
          [checked]="campaign.enabled"
          size="medium"
        >
        </app-switch>
      </div>
      <dropdown-select
        *ngIf="theme === 'light'"
        [options]="options"
        [defaultValue]="setDefaultPercentage(campaign.id)"
        [placeholder]="
          +campaign?.daily_connection_limit_perc === 0
            ? '0%'
            : 'Select a percentage'
        "
        (selectionChanged)="onItemSelect($event, campaign.id)"
      >
      </dropdown-select>
      <app-dropdown
        *ngIf="theme === 'dark'"
        (change)="onItemSelect($event, campaign.id)"
        [isDarkTheme]="true"
        [options]="options"
        placeholder="Select a percentage"
        [defaultValue]="+campaign?.daily_connection_limit_perc"
        theme="dark"
      ></app-dropdown>
    </div>
    <div class="daily-limit-breakdown-wrapper">
      <span class="pipa-help-text flex__row--end">{{
        setWarningText(totalCampaignsAllocatedPercentage)
      }}</span>
      <div class="flex__row--end">
        <span
          *ngIf="!!totalCampaignsAllocatedPercentage"
          class="percentage-total-label"
          >Total:
        </span>
        <span
          class="percentage-total-text"
          [ngClass]="{
            'daily-perc-error-text': +totalCampaignsAllocatedPercentage > 1
          }"
        >
          {{
            totalCampaignsAllocatedPercentage &&
              formatToPercentage(+totalCampaignsAllocatedPercentage)
          }}</span
        >
      </div>
    </div>
  </div>
  <app-mobile-actions *ngIf="isMobile">
    <app-button
      class="opaque"
      label="Save"
      [fullWidth]="true"
      (click)="onSaveClick()"
      style="margin-bottom: 0.35rem"
      [disabled]="+totalCampaignsAllocatedPercentage > 1"
    >
    </app-button>
  </app-mobile-actions>
</app-modal>
