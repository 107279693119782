import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-toggle-button',
  templateUrl: './dropdown-toggle-button.component.html',
  styleUrls: ['./dropdown-toggle-button.component.scss'],
})
export class DropdownToggleButtonComponent {
  @Input() buttonLabel: string; // Default label for the button
  @Input() menuItems: string[] = []; // Array of menu items
  @Output() itemSelected = new EventEmitter<string>(); // Emit the selected item

  onItemSelect(item: string) {
    this.itemSelected.emit(item);
  }
}
