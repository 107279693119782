import { Component, HostListener, Input } from '@angular/core';
import { slideUpDown } from '../animations/slideUpDown';

@Component({
  selector: 'app-mobile-actions',
  templateUrl: './mobile-actions.component.html',
  styleUrls: ['./mobile-actions.component.scss'],
  animations: [slideUpDown]
})
export class MobileActionsComponent {
  @Input() variant: 'primary' | 'secondary' = 'primary';
  isVisible: boolean = true;
  touchStartY: number = 0;
  touchEndY: number = 0;
  swipeThreshold: number = 50; // Minimum distance for a swipe to trigger

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartY = event.changedTouches[0].screenY;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    this.touchEndY = event.changedTouches[0].screenY;
  }

  @HostListener('touchend')
  onTouchEnd() {
    const swipeDistance = this.touchStartY - this.touchEndY;

    // Detect swipe up to show (moving upwards)
    if (swipeDistance > this.swipeThreshold) {
      this.showMobileActions();
    }

    // Detect swipe down to hide (moving downwards)
    if (this.touchEndY - this.touchStartY > this.swipeThreshold) {
      this.hideMobileActions();
    }
  }

  showMobileActions() {
    this.isVisible = true;
  }

  hideMobileActions() {
    this.isVisible = false;
  }
}
