import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class StorageService {
  constructor() { }

  saveToSession(key: string, data: any): void {
    try {
      const dataString = JSON.stringify(data);
      sessionStorage.setItem(key, dataString);
    } catch (error) {
      // console.error('Error saving to sessionStorage', error);
    }
  }

  saveToLocalStorage(key: string, data: any): void {
    try {
      const dataString = JSON.stringify(data);
      localStorage.setItem(key, dataString);
    } catch (error) {
      // console.error('Error saving to localStorage', error);
    }
  }

  getFromSession(key: string): any {
    try {
      const dataString = sessionStorage.getItem(key);
      if (dataString) {
        return JSON.parse(dataString);
      }
      return null;
    } catch (error) {
      // console.error('Error getting from sessionStorage', error);
      return null;
    }
  }

  getFromLocalStorage(key: string): any {
    try {
      const dataString = JSON.parse(localStorage.getItem(key));
      if (dataString) {
        return JSON.parse(dataString);
      }
      return null;
    } catch (error) {
      // console.error('Error getting from localStorage', error);
      return null;
    }
  }
}
