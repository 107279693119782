export const HIDDEN_NAVIGATION_ROUTES = [
  'billing-plans',
  'downloads',
  'download-success',
  'payment/success',
  'linkedin-login',
  'session-expired',
  'send-linkedin-invitations',
  'linkedin-task-manager',
  'send-linkedin-invitations?inProgress=true',
  'linkedin-task-manager?inProgress=true',
];
