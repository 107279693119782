import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, first } from 'rxjs';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import {
  PhoneVerifyErrorCodes,
  usePhoneVerifyStore,
} from 'src/app/store/phone-verify.store';
import { useUserStore } from 'src/app/store/user.store';
import { useToastStore } from 'src/app/store/toast.store';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VerifyPhoneService {
  private createHeaders = () => this.requestHelpers.createHeaders();
  private baseUrl: string = `${environment.apiUrl}/clients/phone-verification`;
  private sendVerifyUrl: string = `${this.baseUrl}/send`;
  private submitVerifyPinUrl: string = `${this.baseUrl}/verify`;

  private phoneVerifyStore = usePhoneVerifyStore.getState;
  private modalManagerStore = useModalManagerStore.getState;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService
  ) { }

  sendVerifyCode = () => {
    this.phoneVerifyStore().sendPhoneVerify.startLoading();
    const request$ = this.http
      .post(this.sendVerifyUrl, undefined, { headers: this.createHeaders() })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.phoneVerifyStore().sendPhoneVerify.succeeded();
            this.modalManagerStore().toggleModal('phoneVerify', true);
          }
        }),
        // need to return a non error observable - this is critical to maintaining the overall observable chain
        catchError((errorRes) => {
          this.phoneVerifyStore().sendPhoneVerify.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  submitVerifyCode = (pin: string) => {
    // Here we can start the loading state for zustand
    this.phoneVerifyStore().submitPhoneVerifyPin.startLoading();
    const request$ = this.http
      .post(this.submitVerifyPinUrl, { pin }, { headers: this.createHeaders() })
      .pipe(
        first(),
        tap((resData: { verified?: boolean }) => {
          if (!resData.hasOwnProperty('errors')) {
            this.phoneVerifyStore().submitPhoneVerifyPin.succeeded();
            useUserStore.setState({ phone_number_verified: true });
            this.modalManagerStore().toggleModal('phoneVerify', false);
            useToastStore.setState({
              isVisible: true,
              content: 'Profile verified successfully',
              variant: 'success',
            });
          } else {
            this.phoneVerifyStore().submitPhoneVerifyPin.failed(
              PhoneVerifyErrorCodes.invalidVerifyCode
            );
          }
        }),
        catchError((errorRes) => {
          this.phoneVerifyStore().submitPhoneVerifyPin.failed(errorRes);
          useToastStore.setState({
            isVisible: true,
            content: 'Invalid verification code',
            variant: 'danger',
          });
          return errorRes;
        })
      );
    return request$;
  };
}
