<div class="message-cell message-cell__{{ variant }} flex">
  <profile-image
    [image]="senderImage"
    [fullName]="senderName"
    size="medium"
  ></profile-image>
  <div
    class="message-cell__content-body-{{ variant }} message-cell-content-body"
  >
    <div class="message-cell__content">{{ message }}</div>
    <!-- <hr class="message-cell__divider" /> -->
    <div class="message-cell__date-time">{{ dateTime | ordinalDateTime }}</div>
  </div>
</div>
