import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { userProfileFeatureKey } from '../store/user/user.reducer';
import * as UserActions from '../store/user/user.actions';
import * as fromRoot from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class UserProfileResolver {
  constructor(private store: Store<fromRoot.AppState>) { }

  resolve(): Observable<any> {
    // Dispatch action to load user profile
    this.store.dispatch(UserActions.fetchUserDetailsStart());

    return this.store.select(userProfileFeatureKey).pipe(
      take(1),
      tap((userState) => {
        // If user state is not loaded, dispatch action to load user profile
        if (!!userState.full_name) {
          this.store.dispatch(UserActions.fetchUserDetailsStart());
        }
      })
    );
  }
}
