import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LinkedinConnectionsService } from 'src/app/services/linkedin-connections/linkedin-connections.service';
import { AlertComponent } from 'src/app/shared/alert/alert/alert.component';
import { PlaceHolderDirective } from 'src/app/shared/directives/placeholder.directive';
import { useLinkedinConnectionsStore } from 'src/app/store/linkedin-connections';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { useToastStore } from 'src/app/store/toast.store';
import {
  AnalyticsManagerService,
  Events,
} from 'src/app/shared/services/analytics-manager.service';

@Component({
  selector: 'activity-log-modal',
  templateUrl: './activity-log-modal.component.html',
  styleUrl: './activity-log-modal.component.scss',
})
export class ActivityLogModalComponent implements OnInit {
  @ViewChild(PlaceHolderDirective, { static: false })
  promptHost: PlaceHolderDirective;
  isVisible: boolean;
  connection: any;
  connectionProfile: any;
  activityData: any;
  isLoading: boolean;
  isSendMessageActive: boolean = false;
  isLoadingMessageSend: boolean = false;
  message: string = '';
  markAsCompletePrompt =
    'When marking your connection as complete pipa will no longer track your activity. You can always come back and set them to active again.';
  markAsActivePrompt =
    'When marking your connection as active pipa will start tracking your activity again.';
  date = '2024-02-01T00:24:56.000Z';

  private dismissPromptSub$: Subscription;
  private promptActionSub$: Subscription;

  constructor(private linkedinConnectionsService: LinkedinConnectionsService) {
    this.isVisible = useModalManagerStore.getState().activityLog;
    this.isLoading = useLinkedinConnectionsStore.getState().isActivityLoading;
  }

  onClose() {
    useModalManagerStore.setState({ activityLog: false });
    useLinkedinConnectionsStore.getState().reset();
    this.message = '';
    this.isSendMessageActive = false;
    this.isLoadingMessageSend = false;
  }

  ngOnInit(): void {
    useModalManagerStore.subscribe((state) => {
      this.isVisible = state.activityLog;
      if (this.isVisible) {
        this.getActivities();
      }
    });
  }

  getActivities() {
    useLinkedinConnectionsStore.subscribe((state) => {
      const connectionData = state.activeConnection;
      this.activityData = state?.connectionActivity;
      this.isLoading = state.isActivityLoading;
      connectionData?.find((c) => (this.connection = c));
    });
  }

  showPrompt(message, actionButtonLabel = 'Continue', theme = 'primary') {
    if (message === this.markAsCompletePrompt) {
      AnalyticsManagerService.recordButtonClick(
        Events.toggleMarkConnectionAsComplete
      );
    } else if (message === this.markAsActivePrompt) {
      AnalyticsManagerService.recordButtonClick(
        Events.toggleMarkConnectionAsActive
      );
    }
    useModalManagerStore.setState({ activityLog: false });
    const viewContainerRef = this.promptHost.viewContainerRef;
    viewContainerRef.clear();
    const ref = viewContainerRef.createComponent(AlertComponent);
    ref.instance.hasAction = true;
    ref.instance.message = message;
    ref.instance.theme = theme;
    ref.instance.label = actionButtonLabel;
    this.promptActionSub$ = ref.instance.onClick.subscribe(() => {
      this.promptActionSub$.unsubscribe();
      viewContainerRef.clear();
      if (message === this.markAsCompletePrompt) {
        AnalyticsManagerService.recordButtonClick(
          Events.markConnectionAsCompleteClicked
        );
        this.markConnectionAsComplete();
      } else if (message === this.markAsActivePrompt) {
        AnalyticsManagerService.recordButtonClick(
          Events.markConnectionAsCompleteClicked
        );
        this.markConnectionAsActive();
      }
      useModalManagerStore.setState({ activityLog: true });
    });
    this.dismissPromptSub$ = ref.instance.close.subscribe(() => {
      this.dismissPromptSub$.unsubscribe();
      viewContainerRef.clear();
      useModalManagerStore.setState({ activityLog: true });
    });
  }

  markConnectionAsComplete() {
    const payload = { details: 'You marked this connection as complete' };
    this.linkedinConnectionsService
      .markConnectionAsComplete(this.connection.id, payload)
      .subscribe((res) => {
        if (!res.hasOwnProperty('errors')) {
          useToastStore.setState({
            isVisible: true,
            content: 'Connection marked as complete',
          });
        }
      });
  }

  markConnectionAsActive() {
    const payload = { details: 'You marked this connection as active' };
    this.linkedinConnectionsService
      .markConnectionAsActive(this.connection.id, payload)
      .subscribe((res) => {
        if (!res.hasOwnProperty('errors')) {
          useToastStore.setState({
            isVisible: true,
            content: 'Connection marked as active',
          });
        }
      });
  }

  onSendMessageChange(e) {
    this.message = e.target.value.trim();
  }

  onToggleMessageInput() {
    AnalyticsManagerService.recordButtonClick(Events.toggleSendMessage);
    this.isSendMessageActive = !this.isSendMessageActive;
  }

  onSendMessageClick() {
    this.isLoadingMessageSend = true;
    if (this.message) {
      AnalyticsManagerService.recordButtonClick(Events.sendMessageClicked);
      this.linkedinConnectionsService
        .sendMessage(
          this.message,
          this.connection.id,
          this.connection.connection_name
        )
        .subscribe((res) => {
          this.isLoadingMessageSend = false;
          if (!res.hasOwnProperty('errors') && res['message'] === 'Success') {
            this.message = '';
            useToastStore.setState({
              isVisible: true,
              content: 'Message sent successfully',
            });
          } else {
            useToastStore.setState({
              isVisible: true,
              content: 'Opps failed to send message, lets try that again',
              variant: 'danger',
            });
          }
        });
    }
  }
}
