import { Component, Input, OnInit, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { ProfileHelperService } from '../services/profile-helper.service';

@Component({
  selector: 'profile-image',
  templateUrl: './profile-image.component.html',
  styleUrl: './profile-image.component.scss',
})
export class ProfileImageComponent implements OnInit, OnChanges {
  @Input() image?: string;
  @Input() fullName?: string;
  @Input() size?: 'small' | 'large' | 'medium' = 'large';
  @Input() variant?: 'primary' | 'secondary' = 'primary';

  constructor(private profileHelperService: ProfileHelperService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setImage(this.image);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image) {
      this.setImage(this.image);
    }
  }

  setImage(url) {
    // if (window.location.origin.includes('localhost')) {
    //   this.image = '';
    //   return;
    // }
    let img = this.renderer.createElement('IMG');
    img.src = url;
    img.onerror = () => {
      this.image = '';
    };
  }

  getProfileInitials(name: string): string | void {
    return this.profileHelperService.getProfileInitials(name);
  }
}
