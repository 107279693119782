import { Component, EventEmitter, Input, Output } from '@angular/core';
import { useAlertBannerStore } from 'src/app/store/alert-banner.store';

@Component({
  selector: 'alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrl: './alert-banner.component.scss',
})
export class AlertBannerComponent {
  @Input() isVisible: boolean = false;
  @Input() message: string;
  @Input() variant: 'warning' | 'error' | 'neutral' = 'warning';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() messageClickable: boolean = false;
  // @Output() onMessageClick: () => void;
  @Input() messageRouterLink?: string;
  @Output() onMessageClick: EventEmitter<void> = new EventEmitter<void>();

  warningIcon = 'assets/icons/warning.svg';
  dangerIcon = 'assets/icons/circle-exclamation-filled.svg';
  activeIcon: string = this.warningIcon;

  constructor() {
  }

  onMessageClicked() {
    this.onMessageClick.emit();
  }

  onClose() {
    this.isVisible = false;
  }

  ngOnInit(): void {
    if (this.variant === 'error') {
      this.activeIcon = this.dangerIcon;
    }
  }
}
