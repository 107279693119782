import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pulsing-indicator',
  templateUrl: './pulsing-indicator.component.html',
  styleUrl: './pulsing-indicator.component.scss'
})
export class PulsingIndicatorComponent {
  @Input() text: string;

}
