import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: `
    <div class="badge-wrapper  {{ variant }}">
      <img
        *ngIf="withIcon"
        class="badge-icon {{ size }}"
        src="{{ icon }}"
        draggable="false"
        alt="badge"
      />
      <span class="badge-text {{ size }}">{{ text }}</span>
    </div>
  `,
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() icon: string;
  @Input() withIcon: boolean = true;
  @Input() text: string;
  @Input() variant: 'standard' | 'navigator' | 'cloud' | 'light' | 'filled';
  @Input() size: 'small' | 'medium' | 'large' = 'small';

  constructor() { }

  ngOnInit(): void {
    this.variant = this.variant || 'standard';
  }
}
