import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BotStatusEnum, BotStatus } from 'src/app/constants/enums/bot-status.enum';

@Component({
  selector: 'app-activity-status-cell',
  templateUrl: './activity-status-cell.component.html',
  styleUrl: './activity-status-cell.component.scss'
})
export class ActivityStatusCellComponent implements OnInit, OnChanges {
  @Input() headerText: string;
  @Input() status: BotStatus = BotStatusEnum.Ready;
  @Input() statusText: string;
  @Input() showPulsingIndicator?: boolean;
  @Output() onViewLive: EventEmitter<void> = new EventEmitter<void>();
  statusColor: string;

  constructor() { }

  ngOnInit(): void {
    this.setStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.setStatus();
    }
  }

  onViewLiveClick(): void {
    this.onViewLive.emit();
  }

  setStatus(): void {
    switch (this.status) {
      case BotStatusEnum.Completed:
        this.statusText = BotStatusEnum.Completed;
        this.statusColor = '#19FC4B';
        break;
      case BotStatusEnum.Running:
        this.statusText = BotStatusEnum.Running;
        this.statusColor = '#2EFDDF';
        break;
      case BotStatusEnum.Stopped:
        this.statusText = BotStatusEnum.Stopped;
        this.statusColor = '#DD5E4B';
        break;
      case BotStatusEnum.Stopping:
        this.statusText = BotStatusEnum.Stopping;
        this.statusColor = '#DD5E4B';
        break;
      case BotStatusEnum.Ready:
        this.statusColor = "#fff";
        this.statusText = BotStatusEnum.Ready;
        break;
      default:
        this.statusColor = '#fff';
        break;
    }
  }

}
