<div
  *ngIf="isVisible"
  class="alert-banner {{ variant }}"
  [ngClass]="{ dark: theme === 'dark' }"
>
  <p
    class="banner-message"
    (click)="onMessageClicked()"
    [ngClass]="{ clickable: messageClickable }"
  >
    <img
      class="alert-banner-icon"
      [src]="activeIcon"
      draggable="false"
      alt="alert"
    />{{ message }}
  </p>
</div>
