import { createPipaStore } from './store.helpers';
import { PipaProduct } from '../services/products-service/PipaProduct';

// We need to fill this interface out to match the API model
export interface UserState {
  id?: string;
  image_url?: string;
  password?: string;
  email?: string;
  enabled?: boolean;
  full_name?: string;
  industry?: string;
  calendly_url?: string;
  active_tier?: string;
  linkedin_lead_list?: any;
  cookie_expiry?: string;
  phone_number?: string;
  phone_country_code?: string;
  linkedin_authenticated?: boolean;
  phone_number_verified?: boolean;
  acknowledged_tour?: boolean;
  has_raw_connections?: boolean;
  created_at?: string;
  updated_at?: string;
  customer_id?: string;
  subscription_id?: string;
  product_id?: number;
  subscription_product?: string;
  subscription_billing_date?: number;
  subscription_active?: boolean;
  subscription_status?: string;
  subscription_canceled?: boolean;
  subscription_canceled_at?: number;
  payment_valid?: boolean;
  lead_sync_active?: boolean | null;
  downloaded_macos_app?: boolean;
  Product?: PipaProduct;
  setUser?: any;
  getUser?: any;
  resetUser?: any;
}

const initialUserState: UserState = {
  id: null,
  image_url: null,
  email: null,
  password: null,
  enabled: null,
  full_name: null,
  industry: null,
  calendly_url: null,
  active_tier: null,
  linkedin_lead_list: null,
  cookie_expiry: null,
  phone_number: null,
  phone_country_code: null,
  linkedin_authenticated: null,
  phone_number_verified: null,
  acknowledged_tour: null,
  has_raw_connections: null,
  customer_id: null,
  subscription_id: null,
  product_id: null,
  subscription_product: null,
  subscription_billing_date: null,
  subscription_active: false,
  subscription_canceled: false,
  subscription_canceled_at: null,
  payment_valid: false,
  lead_sync_active: null,
  downloaded_macos_app: null,
  Product: null,
  created_at: null,
  updated_at: null,
  setUser: () => null,
  getUser: () => null,
  resetUser: () => null,
};

export const useUserStore = (() =>
  createPipaStore<UserState>((set, get) => ({
    ...initialUserState,
    setUser: (user: UserState) => set(user),
    getUser: () => get(),
    resetUser: () => set(initialUserState),
  })))();
