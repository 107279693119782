export enum BotStatusEnum {
  Completed = 'complete',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Ready = 'ready',
};


export type BotStatus = typeof BotStatusEnum[keyof typeof BotStatusEnum];
