import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-squared-badge',
  templateUrl: './squared-badge.component.html',
  styleUrl: './squared-badge.component.scss'
})
export class SquaredBadgeComponent {
  @Input() text: string;
  @Input() imageSrc: string;
  @Input() variant: 'light' | 'dark' = 'light';
  @Input() theme: 'primary' | 'secondary' | 'inverse' = 'primary';

}
