import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { campaignsFeatureKey } from '../campaigns/store/campaigns.reducer';
import * as CampaignsActions from '../campaigns/store/campaigns.actions';
import * as fromRoot from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class CampaignsResolver {
  constructor(private store: Store<fromRoot.AppState>) { }

  resolve(): Observable<any> {
    this.store.dispatch(CampaignsActions.getCampaigns());

    // Wait for campaign data to be available in the store
    return this.store.select(campaignsFeatureKey).pipe(
      filter((campaignState) => campaignState.isLoading === false), // Wait until loading is complete
      take(1), // Complete the observable once the state is ready
      switchMap((campaignState) => {
        if (!campaignState.campaigns || campaignState.campaigns.length === 0) {
          return of([]); // Return an empty array if no campaigns exist
        }
        return of(campaignState.campaigns); // Return the campaigns if they exist
      }),
      catchError((error) => {
        console.error('Error resolving campaigns:', error);
        return of([]); // Handle errors gracefully by returning an empty array
      })
    );
  }
}
