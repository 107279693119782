<div class="mobile-actions-container flex__column--center {{ variant }}">
  <img
    *ngIf="!isVisible"
    src="assets/icons/caret-up.svg"
    alt="caret-up"
    class="caret-up-img"
    draggable="false"
  />
  <span class="thumb-swipe-indicator" *ngIf="isVisible"></span>
  <div class="mobile-actions" *ngIf="isVisible" @slideUpDown>
    <ng-content></ng-content>
  </div>
</div>
