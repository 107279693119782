import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SubscriptionGuard } from './guards/subscription.guard';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { CampaignsResolver } from './resolvers/campaigns.resolver';
import { LeadListsResolver } from './resolvers/lead-lists.resolver';
import { MessageTemplatesResolver } from './resolvers/message-templates.resolver';
import { DashboardDataResolver } from './resolvers/dashboard.resolver';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'downloads',
    loadChildren: () => import('./downloads/downloads.module').then(module => module.DownloadsModule)
  },
  {
    path: 'download-success',
    loadChildren: () => import('./downloads/download-success/download-success.module').then(module => module.DownloadSuccessModule)
  },
  {
    path: 'campaigns',
    resolve: { leadLists: LeadListsResolver },
    canActivate: [SubscriptionGuard],
    loadChildren: () => import('./campaigns/campaigns.module').then(module => module.CampaignsModule)
  },
  {
    path: 'leads',
    loadChildren: () => import('./leads/leads.module').then(module => module.LeadsModule),
    canActivate: [SubscriptionGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: 'linkedin-login',
    loadChildren: () => import('./linkedin-auth/linkedin-auth.module').then(module => module.LinkedinAuthModule)
  },
  {
    path: 'message-templates',
    resolve: { messageTemplates: MessageTemplatesResolver },
    canActivate: [SubscriptionGuard],
    loadChildren: () => import('./message-templates/message-templates.module').then(module => module.MessageTemplatesModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(module => module.UserProfileModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payments.module').then(module => module.PaymentsModule)
  },
  {
    path: 'billing-plans',
    loadChildren: () => import('./billing-plans/billing-plans.module').then(module => module.BillingPlansModule)
  },
  {
    path: 'dashboard',
    resolve: { dashboardData: DashboardDataResolver },
    loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule)
  },
  {
    path: 'leads',
    loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule)
  },
  {
    path: 'session-expired',
    loadChildren: () => import('./session-expired/session-expired.module').then(m => m.SessionExpiredModule)
  },
  {
    path: 'send-linkedin-invitations',
    canActivate: [SubscriptionGuard],
    resolve: { user: UserProfileResolver, campaigns: CampaignsResolver }, loadChildren: () => import('./linkedin-invitations/linkedin-invitations.module').then(m => m.LinkedinInvitationsModule)
  },
  {
    path: 'linkedin-task-manager',
    canActivate: [SubscriptionGuard],
    resolve: { user: UserProfileResolver, campaigns: CampaignsResolver }, loadChildren: () => import('./linkedin-task-manager/linkedin-task-manager.module').then(m => m.LinkedinTaskManagerModule)
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
