<div>
  <div [formGroup]="templateForm" *ngIf="messageTemplateId">
    <message-template-options
      *ngIf="
        showDripInput ||
        showMessageBeforeConnectionInput ||
        showMessageAfterConnectionInput
      "
      [isProfessional]="isProfessional"
      [addMode]="false"
      [campaignId]="campaignId"
      [isDripCampaign]="isDripCampaign"
      [isConnectWithMessage]="isMessageBeforeConnection"
      [isMessageAfterConnect]="isMessageAfterConnection"
    >
    </message-template-options>
    <app-text-area
      *ngIf="showMessageBeforeConnectionInput"
      (change)="onChange('message_before_connection', $event)"
      [value]="templateForm.controls['message_before_connection'].value"
      [isError]="
        isError('message_before_connection') ||
        hasExceededMaxCharacters(messageBeforeConnectLen)
      "
      [label]="messageBeforeConnectLabel"
      errorMessage="{{ messageBeforeConnectError() }}"
      name="message_before_connection"
      id="message_before_connection"
      formControlName="message_before_connection"
    >
    </app-text-area>
    <app-text-area
      *ngIf="showMessageAfterConnectionInput"
      (change)="onChange('first_interaction', $event)"
      [value]="templateForm.controls['first_interaction'].value"
      [isError]="isError('first_interaction')"
      [errorMessage]="'Send message after connection' + errorMessage"
      label="Message after connection"
      name="first_interaction"
      id="first_interaction"
      formControlName="first_interaction"
    >
    </app-text-area>
    <app-text-area
      *ngIf="showDripInput"
      (change)="onChange('first_drip', $event)"
      [value]="templateForm.controls['first_drip'].value"
      [isError]="isError('first_drip')"
      [errorMessage]="'Include drip campaign' + errorMessage"
      label="First drip message"
      name="first_drip"
      id="first_drip"
      formControlName="first_drip"
    >
    </app-text-area>
    <app-text-area
      *ngIf="showDripInput"
      (change)="onChange('second_drip', $event)"
      [value]="templateForm.controls['second_drip'].value"
      label="Second drip message (optional)"
      name="second_drip"
      id="second_drip"
      formControlName="second_drip"
    >
    </app-text-area>
    <app-text-area
      *ngIf="showDripInput"
      (change)="onChange('final_drip', $event)"
      [value]="templateForm.controls['final_drip'].value"
      label="Final drip message (optional)"
      name="final_drip"
      id="final_drip"
      formControlName="final_drip"
    >
    </app-text-area>
  </div>
  <div class="flex__row--end message-template-actions">
    <app-button
      *ngIf="messageTemplates.length"
      [disabled]="isDisabled()"
      class="primary"
      (click)="onSave()"
      label="Activate Campaign"
    ></app-button>
    <app-button
      toRoute="{{ '/message-templates/add-message-template' }}"
      *ngIf="!messageTemplates.length"
      class="primary"
      label="Add Message Template"
    ></app-button>
  </div>
</div>
