<div
  class="selectable-profile-cell"
  [ngClass]="{ 'dark-theme': theme === 'dark', _easeLeftToRight: animate }"
  (click)="onSelect()"
>
  <div class="profile-content">
    <input
      *ngIf="showCheckbox && !isLoading"
      type="checkbox"
      class="select-checkbox"
      [checked]="isSelected && selectable"
      [disabled]="!selectable"
    />
    <ng-container
      *skeleton="
        isLoading;
        repeat: 1;
        className: 'mb-2';
        height: '40px';
        width: '40px';
        borderRadius: '50%'
      "
    >
      <profile-image
        [fullName]="result.lead_name"
        [image]="result.image_url"
      ></profile-image>
    </ng-container>
    <div class="profile-details">
      <ng-container
        *skeleton="
          isLoading;
          repeat: 3;
          className: 'mb-2';
          height: '20px';
          width: 'rand';
          borderRadius: '10px'
        "
      >
        <div (click)="$event.stopPropagation()" class="name">
          <a [href]="result.profile_url" target="_blank" class="profile-link">{{
            result.lead_name || result?.connection_name
          }}</a>
          <span *ngIf="distance" class="distance number">· {{ distance }}</span>
        </div>
        <div class="jobtitle details-text">{{ result.job_title }}</div>
        <div class="location details-text">{{ result.location }}</div>

        <ng-container
          *ngIf="
            result?.leadListId || result?.campaignId || result?.leadListName
          "
        >
          <div class="constraint-indicator-wrapper">
            <app-squared-badge
              *ngIf="result?.campaignId"
              [text]="result?.campaignName"
              [variant]="theme === 'dark' ? 'inverse' : 'primary'"
              [imageSrc]="
                theme === 'dark'
                  ? 'assets/icons/radar-light.svg'
                  : 'assets/icons/radar-active.svg'
              "
            ></app-squared-badge>
            <app-squared-badge
              *ngIf="result?.leadListId || result?.leadListName"
              [text]="result?.leadListName"
              [variant]="theme === 'dark' ? 'inverse' : 'secondary'"
              [imageSrc]="
                theme === 'dark'
                  ? 'assets/icons/list-light.svg'
                  : 'assets/icons/list-icon.svg'
              "
            ></app-squared-badge>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- <div *ngIf="lead" class="profile-activity-card-container">
  <div class="profile-activity-card-wrapper">
    <app-selectable-profile-cell
      theme="dark"
      [selectable]="false"
      [result]="lead"
    ></app-selectable-profile-cell>
  </div>
</div> -->
