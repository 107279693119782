import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDateTime'
})
export class OrdinalDateTimePipe implements PipeTransform {
  transform(value: Date | string | number): string {
    if (!value) return '';

    const date = new Date(value);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';

    const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${month} ${this.getDayWithOrdinal(day)}, ${year} at ${formattedHours}:${formattedMinutes}${period}`;
  }

  private getDayWithOrdinal(day: number): string {
    if (day > 3 && day < 21) return `${day}th`; // Handle "teen" numbers
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }
}
