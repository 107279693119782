import { createAction, props } from '@ngrx/store';

// Action to add a profile to interactedProfiles
export const addInteractedProfile = createAction(
  '[Linkedin Connection Request] Add Interacted Profile',
  props<{ profile: any }>()
);

// Action to clear all interacted profiles
export const clearInteractedProfiles = createAction(
  '[Linkedin Connection Request] Clear Interacted Profiles'
);

// Action to navigate to the next profile
export const nextProfile = createAction(
  '[Linkedin Connection Request] Next Profile'
);

// Action to navigate to the previous profile
export const previousProfile = createAction(
  '[Linkedin Connection Request] Previous Profile'
);

// Action to start the connection invite process
export const startConnectionInviteProcess = createAction(
  '[Linkedin Connection Request] Start Connection Invite Process'
);

// Action to stop the connection invite process
export const stopConnectionInviteProcess = createAction(
  '[Linkedin Connection Request] Stop Connection Invite Process'
);

// Action to mark the process as completed
export const completeConnectionInviteProcess = createAction(
  '[Linkedin Connection Request] Complete Connection Invite Process'
);
