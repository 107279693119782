import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { map, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { RequestHelpersService } from './shared/services/request-helpers.service';
import {
  Route,
  useNavigationStore as navStore,
} from './store/navigation.store';
import { LocalStorageKeys } from './constants/storageKeys';

import * as fromRoot from './store/app.reducer';
import * as AuthActions from './auth/store/auth.actions';
import * as UserActions from './store/user/user.actions';
import { AnalyticsManagerService } from './shared/services/analytics-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location],
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  setCurrentPage = navStore.getState().setCurrentPage;
  activeRoute = null;
  title: string = 'pipa';
  loadingDataImg: boolean = false;
  isPhoneVerified: boolean = false;

  private userAuthSub$: Subscription;

  constructor(
    private store: Store<fromRoot.AppState>,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private requestHelpersService: RequestHelpersService
  ) {
    router.events.subscribe((val) => {
      const basePath = location
        .path()
        .split('/')
        .filter((chunk) => chunk)[0];
      let chunks = location
        .path()
        .split('/')
        .filter((chunk) => chunk);
      let titleArray = [];
      chunks.forEach((chunk) => {
        let title = chunk
          .split('-')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ');

        if (title.includes('?')) {
          title = title.split('?')[0];
        }

        titleArray.push(title);
      });

      this.titleService.setTitle(this.title + ' | ' + titleArray.join(' | '));

      this.setCurrentPage(basePath as Route);
      this.activeRoute = navStore.getState().currentPage;
    });
    this.userAuthSub$ = this.store
      .select('auth')
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        this.isAuthenticated = !!user;
      });

    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const userData: {
          _token: string;
        } = JSON.parse(localStorage.getItem(LocalStorageKeys.CLIENT_JWT));

        const isTokenExpired = this.requestHelpersService.isJwtExpired();

        if (!userData && this.activeRoute !== 'auth') {
          router.navigate(['/auth']);
        } else if (userData && isTokenExpired && this.activeRoute !== 'auth') {
          router.navigate(['/auth']);
        } else {
          this.store.dispatch(UserActions.fetchUserDetailsStart());
        }

        AnalyticsManagerService.recordPageView(event.urlAfterRedirects);
      });
    /** END : Code to Track Page View  using gtag.js */
  }

  ngOnInit() {
    this.store.dispatch(AuthActions.autoLogin());
  }

  ngOnDestroy() {
    // this.userAuthSub$.unsubscribe();
  }
}
