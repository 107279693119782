import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VerifyPhoneService } from 'src/app/services/verify-phone-service/verify-phone.api.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { usePhoneVerifyStore } from 'src/app/store/phone-verify.store';
import { useUserStore } from 'src/app/store/user.store';
import {
  CleanPhoneNumber,
  FormatPhoneNumber,
} from 'src/app/helpers/number-formatting';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-verify-phone-modal',
  template: `
    <app-modal [visible]="visible" [usePadding]="false">
      <!-- <img (click)="onClose()" src="assets/icons/x-close-gray.svg" alt="X" draggable="false" class="_close-icon close-btn"> -->
      <div class="pin-verify-wrapper">
        <h5 class="pin-verify-header">
          {{ isEmailVerification ? 'Email' : 'Phone' }} verification
        </h5>
        <div class="flex__row--space-between user-phone-detail">
          <div class="flex__column sent-to-detail">
            <div *ngIf="!editMode" class="flex__column">
              <span class="sent-to-help-block s5">We sent a code to</span>
              <span *ngIf="!isEmailVerification" class="s5"
                >+1 {{ formatPhoneNumber(phoneNumber) }}</span
              >
              <span *ngIf="isEmailVerification" class="s5">{{ email }}</span>
            </div>
            <form
              *ngIf="editMode"
              [formGroup]="editPhoneForm"
              [ngClass]="{ 'edit-email-form': isEmailVerification }"
              class="edit-phone-form"
            >
              <input
                *ngIf="!isEmailVerification"
                autocomplete="off"
                class="form-control"
                formControlName="phone_number"
                (change)="onPhoneChange($event)"
                [value]="this.editPhoneForm?.controls['phone_number']?.value"
              />
              <input
                *ngIf="isEmailVerification"
                class="form-control email-input"
                formControlName="email"
                (change)="onEmailChange($event)"
                [value]="this.editPhoneForm?.controls['email']?.value"
              />
            </form>
          </div>
          <div class="flex__center edit-actions-wrapper">
            <app-button
              [disabled]="
                editMode && !editPhoneForm?.controls['phone_number']?.valid
              "
              (click)="onEditClick()"
              class="{{ editMode ? 'primary' : 'dismiss' }}"
              label="{{ editMode ? 'Save' : 'Edit' }}"
            ></app-button>
          </div>
        </div>
        <div class="flex__column verify-phone-input-wrapper">
          <span class="flex__center s5 code-input-help-block"
            >Enter your code</span
          >
          <pin-mask-input
            (codeChanged)="onChange($event)"
            (codeCompleted)="onVerify()"
          ></pin-mask-input>
          <span class="flex__center s5 expires-in-help-block"
            >Expires in 5 minutes.</span
          >
        </div>
        <div class="flex__column action-wrapper">
          <button
            (click)="onResendCodeClick()"
            class="light flex__center"
            id="resend-code-btn"
            [disabled]="!allowResend"
          >
            Resend Code
          </button>
        </div>
      </div>
    </app-modal>
  `,
  styleUrls: ['./verify-phone-modal.component.scss'],
  providers: [VerifyPhoneService],
})
export class VerifyPhoneModalComponent implements OnInit, OnDestroy {
  visible: boolean;
  pinValue: string;
  value: string;
  loading: boolean;
  error: any;
  inputArray: any[] = [];
  phoneNumber: string;
  editMode = false;
  editPhoneForm: FormGroup;
  formatPhoneNumber = FormatPhoneNumber;
  cleanPhoneNumber = CleanPhoneNumber;
  allowResend = false;
  countryCode: string;
  email: string;
  isEmailVerification: boolean;

  private modalManagerStore = useModalManagerStore.getState;

  private pinSubscription$: Subscription;
  private verifyPhoneSubscription$: Subscription;

  constructor(
    private verifyPhoneService: VerifyPhoneService,
    private userService: UserProfileService
  ) { }

  toggleEditMode = () => (this.editMode = !this.editMode);

  onChange(event) {
    this.value = event;
  }

  onEmailChange(event) {
    this.editPhoneForm.controls['email'].setValue(event.target.value);
  }

  onPhoneChange(event) {
    this.editPhoneForm.controls['phone_number'].setValue(event.target.value);
  }

  initEditForm() {
    // let phoneNumber = this?.phoneNumber && this?.formatPhoneNumber(this?.phoneNumber)
    let phoneNumber =
      this?.phoneNumber && this?.cleanPhoneNumber(this?.phoneNumber);

    this.editPhoneForm = new FormGroup({
      // phone_number: new FormControl(phoneNumber, [Validators.required, Validators.maxLength(14), Validators.minLength(14)]),
      phone_number: new FormControl(phoneNumber, [
        Validators.required,
        Validators.minLength(10),
      ]),
      email: new FormControl(this.email, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  onSave() {
    if (!this.isEmailVerification) {
      const updatedPhoneNumber = this.cleanPhoneNumber(
        this.editPhoneForm?.controls['phone_number'].value
      );
      if (this.phoneNumber === updatedPhoneNumber) return;
      this.allowResend = true;
      this.phoneNumber = updatedPhoneNumber;
      this.userService.updateUserProfile(this.editPhoneForm.value);
    } else {
      const updatedEmail = this.editPhoneForm?.controls['email'].value;
      if (this.email === updatedEmail) return;
      this.allowResend = true;
      this.email = updatedEmail;
      this.userService.updateUserProfile(this.editPhoneForm.value);
    }
  }

  onEditClick() {
    if (!this.editMode) {
      this.toggleEditMode();
      this.initEditForm();
    } else {
      this.onSave();
      this.toggleEditMode();
    }
  }

  onClose() {
    this.modalManagerStore().toggleModal('phoneVerify', false);
  }

  onVerify() {
    this.pinSubscription$ = this.verifyPhoneService
      .submitVerifyCode(this.value)
      .pipe(take(1))
      .subscribe();
  }

  onResendCodeClick() {
    const req$ = this.verifyPhoneService.sendVerifyCode();
    this.verifyPhoneSubscription$ = req$.pipe(take(1)).subscribe();
    this.allowResend = false;
  }

  ngOnInit(): void {
    useUserStore.subscribe((s) => {
      this.phoneNumber = s?.phone_number;
      this.countryCode = s?.phone_country_code;
      this.email = s?.email;
      if (this.countryCode === '+1') {
        this.isEmailVerification = false;
      } else {
        this.isEmailVerification = true;
      }
    });

    usePhoneVerifyStore.subscribe(
      (s) => s.submitPhoneVerifyPin,
      (newState) => {
        this.loading = newState.loading;
        this.error = newState.error;
      }
    );

    useModalManagerStore.subscribe(
      (s) => s.phoneVerify,
      (newState) => {
        this.visible = newState;
      }
    );
  }

  ngOnDestroy() {
    if (this.pinSubscription$) {
      this.pinSubscription$.unsubscribe();
    }
    if (this.verifyPhoneSubscription$) {
      this.verifyPhoneSubscription$.unsubscribe();
    }
  }
}
