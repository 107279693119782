<div class="activity-status-cell flex__center">
  <div class="activity-status-cell__content flex__column--center">
    <span class="activity-status-cell__header-text">{{ headerText }}</span>
    <span
      class="activity-status-cell__status-text"
      [ngStyle]="{ color: statusColor }"
      >{{ statusText }}</span
    >
    <div
      class="activity-status-cell__status-indicator"
      [ngStyle]="{ background: statusColor }"
    ></div>
  </div>
  <app-pulsing-indicator
    *ngIf="showPulsingIndicator"
    text="View live"
    (click)="onViewLive.emit()"
  ></app-pulsing-indicator>
</div>
