import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

export interface SelectableProfileContract {
  urn_id?: string;
  distance?: string;
  job_title?: string;
  location?: string;
  lead_name?: string;
  connection_name?: string;
  profile_url?: string;
  image_url?: string;
  campaignId?: number;
  campaignName?: string;
  leadListId?: number;
  leadListName?: string;
  connectionId?: number;
  connectionStatus?: string;
}

@Component({
  selector: 'app-selectable-profile-cell',
  templateUrl: './selectable-profile-cell.component.html',
  styleUrls: ['./selectable-profile-cell.component.scss']
})
export class SelectableProfileCellComponent implements OnInit, OnChanges {
  @Input() result: any;
  @Input() selectable: boolean;
  @Input() showCheckbox: boolean = true;
  @Input() isSelected: () => boolean;
  @Input() isLoading: boolean;
  @Output() selected = new EventEmitter<any>();
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() animate: boolean;
  distance: string;

  constructor() { }

  ngOnInit(): void {
    if (!this.isLoading) {
      this.distance = this.setDistance(this.result.distance);
    }
    this.setIsSelectable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.result && changes.result?.currentValue.leadListId || changes.result?.currentValue.leadListName || changes.result?.currentValue.campaignId) {
      this.setIsSelectable();
    }
  }

  onSelect(): void {
    if (this.selectable) {
      this.selected.emit(this.result);
    }
  }

  setIsSelectable(): void {
    this.selectable = !(this.result?.connectionId || this.result?.campaignId || this.result?.leadListId || this.result?.leadListName);
  }

  setDistance(distance: string) {
    switch (distance) {
      case 'DISTANCE_1':
        return '1st';
      case 'DISTANCE_2':
        return '2nd';
      case 'DISTANCE_3':
        return '3rd';
      default:
        return '';
    }
  }

}
