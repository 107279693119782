import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-cell',
  templateUrl: './message-cell.component.html',
  styleUrl: './message-cell.component.scss'
})
export class MessageCellComponent {
  @Input() message: string;
  @Input() senderName: string;
  @Input() senderImage: string;
  @Input() dateTime?: string;
  @Input() variant?: 'primary' | 'inverse' = 'inverse';

  constructor() { }

}
