import { createAction, props } from '@ngrx/store';

// Action to add a profile to interactedProfiles
export const addInteractedProfile = createAction(
  '[Linkedin Profile Activity] Add Interacted Profile',
  props<{ profile: any }>()
);

// Action to update a profile in interactedProfiles
export const updateInteractedProfile = createAction(
  '[Linkedin Profile Activity] Update Interacted Profile',
  props<{ index: number; updatedProfile: any }>()
);

// Action to clear all interacted profiles
export const clearInteractedProfiles = createAction(
  '[Linkedin Profile Activity] Clear Interacted Profiles'
);

// Action to navigate to the next profile
export const nextProfile = createAction(
  '[Linkedin Profile Activity] Next Profile'
);

// Action to navigate to the previous profile
export const previousProfile = createAction(
  '[Linkedin Profile Activity] Previous Profile'
);

// Action to start the connection invite process
export const startLinkedinProfileActivityProcess = createAction(
  '[Linkedin Profile Activity] Start Profile Activity Process'
);

// Action to stop the connection invite process
export const stopLinkedinProfileActivityProcess = createAction(
  '[Linkedin Profile Activity] Stop Profile Activity Process'
);

// Action to mark the process as completed
export const completeLinkedinProfileActivityProcess = createAction(
  '[Linkedin Profile Activity] Complete Profile Activity Process'
);

// Action to set the active profile index
export const setActiveProfile = createAction(
  '[Linkedin Profile Activity] Set Active Profile',
  props<{ index: number }>()
);

export const incrementProcessingIndex = createAction(
  '[Linkedin Profile Activity] Increment Processing Index'
);
