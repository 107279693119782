import { createReducer, on } from '@ngrx/store';
import * as LinkedinConnectionRequestActions from './linkedin-connection-request.actions';

export interface LinkedinConnectionRequestState {
  interactedProfiles: any[];
  activeProfileIndex: number;
  isInviteInProgress: boolean;
}

export const initialState: LinkedinConnectionRequestState = {
  interactedProfiles: [],
  activeProfileIndex: 0,
  isInviteInProgress: false,
};

export const linkedinConnectionRequestReducer = createReducer(
  initialState,

  // Add interacted profile
  on(LinkedinConnectionRequestActions.addInteractedProfile, (state, { profile }) => ({
    ...state,
    interactedProfiles: [...state.interactedProfiles, profile]
  })),

  // Clear interacted profiles
  on(LinkedinConnectionRequestActions.clearInteractedProfiles, (state) => ({
    ...state,
    interactedProfiles: [],
    activeProfileIndex: 0,
    isInviteInProgress: false,
  })),

  // Navigate to the next profile
  on(LinkedinConnectionRequestActions.nextProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex < state.interactedProfiles.length - 1
      ? state.activeProfileIndex + 1
      : state.activeProfileIndex
  })),

  // Navigate to the previous profile
  on(LinkedinConnectionRequestActions.previousProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex > 0
      ? state.activeProfileIndex - 1
      : state.activeProfileIndex
  })),

  // Start the connection invite process
  on(LinkedinConnectionRequestActions.startConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: true
  })),

  // Stop the connection invite process
  on(LinkedinConnectionRequestActions.stopConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: false
  })),

  // Complete the connection invite process
  on(LinkedinConnectionRequestActions.completeConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: false
  }))
);

export const linkedinConnectionRequestFeatureKey = 'linkedinConnectionRequest';

export const linkedinConnectionRequestFeatureState = (state: any) => state[linkedinConnectionRequestFeatureKey];
