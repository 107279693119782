import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { campaignDailyLimitPercentages, dailyPercentageDefaultOptions } from 'src/app/constants/campaignDailyLimitPercentages';
import { Campaign } from 'src/app/campaigns/campaigns.model';
import { Subscription, map } from 'rxjs';
import { campaignsFeatureKey } from 'src/app/campaigns/store/campaigns.reducer';
import { Store } from '@ngrx/store';
import { FormatToPercentage } from 'src/app/helpers/number-formatting';
import * as fromRoot from '../../store/app.reducer';
import * as CampaignsActions from '../../campaigns/store/campaigns.actions';
import { CampaignsService } from 'src/app/campaigns/services/campaigns.api.service';
import { useToastStore } from 'src/app/store/toast.store';
import { PipaTourService } from 'src/app/shared/services/pipa-tour.service';

@Component({
  selector: 'campaign-daily-limit-modal',
  templateUrl: './campaign-daily-limit-modal.component.html',
  styleUrl: './campaign-daily-limit-modal.component.scss',
})
export class CampaignDailyLimitModalComponent {
  @Input() isVisible: boolean;
  @Input() theme: 'light' | 'dark' = 'light';
  campaigns: Campaign[];
  campaignsDailyLimitArray: any[] = [];
  totalCampaignsAllocatedPercentage: number;
  formatToPercentage = FormatToPercentage;
  dailyLimitTooltip = `The daily limit is the maximum number of invitations (by percentage) that each campaign can send per day. Example: if you have 2 campaigns and you set the daily limit to 50% for both of them, the total invites will be split between the two campaigns evenly.`;
  options: any[];
  isMobile = false;
  defaultValue: string;

  campaignsSub$: Subscription;

  constructor(
    private store: Store<fromRoot.AppState>,
    private campaignsService: CampaignsService,
    private pipaTourService: PipaTourService,
    private cdr: ChangeDetectorRef
  ) {
    this.isVisible = useModalManagerStore.getState().campaignDailyLimit;
    this.campaignsSub$ = this.store
      .select(campaignsFeatureKey)
      .pipe(map((campaignsState) => campaignsState.campaigns))
      .subscribe((campaigns) => {
        this.campaigns = campaigns.filter((campaign) => campaign.enabled);

        this.campaignsDailyLimitArray = [];
        this.campaigns.forEach((campaign) => {
          this.defaultValue = this.setDefaultPercentage(campaign.daily_connection_limit_perc)
          this.campaignsDailyLimitArray.push({
            campaignId: campaign.id,
            limitPerc: +campaign.daily_connection_limit_perc,
          });
        });
      });
  }

  onItemSelect(selectedItem: any, campaignId: number) {
    const updatedCampaign = this.campaignsDailyLimitArray.find(
      (campaign) => campaign.campaignId === campaignId
    );
    if (this.theme === 'dark') {
      updatedCampaign.limitPerc = +selectedItem?.target?.value || 0;
    } else {
      updatedCampaign.limitPerc = selectedItem?.id || 0;
    }
    this.totalCampaignsAllocatedPercentage = this.campaignsDailyLimitArray
      .reduce((acc, campaign) => acc + campaign.limitPerc, 0)
      .toFixed(2);
  }

  setDefaultPercentage(campaignId: number) {
    const perc = this.campaignsDailyLimitArray.find(
      (item) => item.campaignId === campaignId
    )?.limitPerc;
    return perc;
  }

  setWarningText(percentage) {
    if (
      this.totalCampaignsAllocatedPercentage &&
      +this.totalCampaignsAllocatedPercentage < 1
    ) {
      percentage = 1 - this.totalCampaignsAllocatedPercentage;
      return `Your campaign invites are ${this.formatToPercentage(
        percentage.toString()
      )} below your daily limit.`;
    }
    if (
      this.totalCampaignsAllocatedPercentage &&
      +this.totalCampaignsAllocatedPercentage > 1
    ) {
      percentage = this.totalCampaignsAllocatedPercentage - 1;
      return `Your campaign invites are ${this.formatToPercentage(
        percentage.toString()
      )} above your daily limit.`;
    }
    return;
  }

  onCloseModalClick = () => {
    useModalManagerStore.setState({
      campaignDailyLimit: false,
    });
  };

  onSaveClick = () => {
    this.campaignsService
      .updateDailyLimitPercentage(this.campaignsDailyLimitArray)
      .subscribe((res) => {
        let toastContent = `Your daily limits have been updated.`;
        useToastStore.setState({
          isVisible: true,
          content: `${toastContent}`,
          variant: 'success',
        });
      });
    useModalManagerStore.setState({
      campaignDailyLimit: false,
    });
  };

  evenlyDistributeWeights() {
    this.campaignsDailyLimitArray = [];
    let activeCampaigns = this.campaigns?.length;
    if (!activeCampaigns) return;
    this.campaigns.forEach((campaign) => {
      this.campaignsDailyLimitArray.push({
        campaignId: campaign.id,
        limitPerc: +(1 / activeCampaigns).toFixed(2),
      });
    });
  }

  onToggleCampaign(e: any, id: number, enabled: boolean) {
    let updatedCampaign = { id: id, enabled: enabled };
    this.store.dispatch(
      CampaignsActions.updateCampaign({ campaign: updatedCampaign })
    );

    this.campaignsDailyLimitArray = this.campaignsDailyLimitArray.filter(
      (campaign) => campaign.campaignId !== id
    );
  }

  ngOnInit(): void {
    useModalManagerStore.subscribe(
      (state) => (this.isVisible = state.campaignDailyLimit)
    );

    if (this.theme === 'dark') {
      this.pipaTourService.setDarkTheme();
      this.options = dailyPercentageDefaultOptions;
    } else {
      this.options = campaignDailyLimitPercentages;
    }

    this.checkViewport();
    window.addEventListener('resize', this.checkViewport.bind(this));
  }

  ngOnDestroy(): void {
    if (this.campaignsSub$) this.campaignsSub$.unsubscribe();
    this.campaignsDailyLimitArray = [];
  }

  // Method to check if the viewport is Tablet width or smaller
  checkViewport(): void {
    this.isMobile = window.innerWidth <= 590;
    this.cdr.detectChanges();
  }

}
