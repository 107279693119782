import { Injectable } from '@angular/core';
import { helpContent } from 'src/app/constants/constants';
import * as introJs from 'intro.js/intro.js';
import { VariableHighlightService } from './variable-highlight.service';

@Injectable({
  providedIn: 'any',
})
export class PipaTourService {
  introJS = null;
  isDarkTheme = false;

  constructor(private highlightService: VariableHighlightService) { }

  highlightVariable = (textBlock: string) => {
    return this.highlightService.highlightVariable(textBlock);
  };

  setDarkTheme() {
    this.isDarkTheme = true;
  }

  // https://www.linkedin.com/help/sales-navigator/answer/a107069

  showCampaignTour() {
    this.introJS = introJs();

    this.introJS
      .setOptions({
        doneLabel: 'Okay',
        steps: [
          // {
          //   element: '#include_drip_campaign',
          //   title: `<img src="assets/images/std-btn-demo.svg" style="width: 200px" />`,
          //   intro:
          //     'Linkedin Standard campaigns are designed for standard users who haven’t upgraded to a linkedin paid subscription. Linkedin Standard campaigns have maximum of 25 connections per day and filter your target market by using search terms.',
          // },
          // {
          //   element: '#campaignDropdownMenuButton',
          //   title: `<img src="assets/images/nav-btn-demo.svg" style="width: 200px" />`,
          //   intro:
          //     'Sales Navigator campaigns are designed for users who have upgraded to the <a rel="noreferrer" href="https://business.linkedin.com/sales-solutions" target="_blank" class="a-link">Linkedin Sales Navigator</a> subscription. Sales Navigator campaigns have maximum of 35 connections per day and utilize robust filtering features to build your target audience.',
          // }
          {
            element: '#include_drip_campaign',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro: `${helpContent.DRIP_CAMPAIGN}`,
          },
        ],
      })
      .start();
  }

  showEnableCampaignTour() {
    this.introJS = introJs().refresh();

    this.introJS
      .setOptions({
        dontShowAgain: true,
        dontShowAgainCookie: 'pipaTour-enableCampaign',
        doneLabel: 'Okay',
        steps: [
          {
            element: '#campaign_switch',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              'Turn your campaigns on and off using this switch. When campaigns are off they will not run.',
          },
          {
            element: '#campaign-card-list-item',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro: 'Click on the campaign card to view more details.',
          },
        ],
      })
      .start();
  }

  showMessageTemplateTour() {
    this.introJS = introJs();

    this.introJS
      .setOptions({
        doneLabel: 'Okay',
        steps: [
          {
            element: '#action-bar-submit',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              'All users will need to download the pipa desktop app to interact with Linkedin and run campaigns.',
          },
          {
            element: '#pipaSuggestBtn',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              'pipa can create a message template for you, click pipa suggest to generate a message template.',
          },
        ],
      })
      .start();
  }

  showDownloadAppTour() {
    this.introJS = introJs();

    this.introJS
      .setOptions({
        doneLabel: 'Got it',
        steps: [
          {
            element: '#intelDownloadBtn',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              "Intel Chips are typically manufactured prior to 2020. To check if your Mac has an Intel chip, click the Apple icon in the top left corner of your screen and select 'About This Mac'.",
          },
          {
            element: '#appleDownloadBtn',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              "Apple Chips are typically manufactured after 2020. To check if your Mac has an Apple chip, click the Apple icon in the top left corner of your screen and select 'About This Mac'.",
          },
        ],
      })
      .start();
  }

  showPipaSuggestTour() {
    this.introJS = introJs();

    this.introJS
      .setOptions({
        dontShowAgain: true,
        dontShowAgainCookie: 'pipaTour-pipaSuggestTour',
        doneLabel: 'Okay',
        steps: [
          {
            element: '#pipaSuggestBtn',
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro:
              'pipa can create a message template for you, click pipa suggest and generate a message template.',
          },
        ],
      })
      .start();
  }

  showTooltip(targetElement: string, content: any, tooltipPosition?: string) {
    this.introJS = introJs();
    // this.isDarkTheme && this.setDarkTheme();

    this.introJS
      .setOptions({
        highlightClass: `pipaTooltip`,
        tooltipClass: `${this.isDarkTheme ? 'dark-theme' : ''}`,
        doneLabel: 'Got it',
        tooltipPosition: tooltipPosition || 'bottom',
        showProgress: false,
        scrollToElement: false,
        showBullets: false,
        steps: [
          {
            element: targetElement,
            title: `<img src="assets/images/pipa_headshot.png" style="width: 35px" />`,
            intro: content.replace(
              /\$prospect/g,
              `<strong style="color: #635AFF;">$prospect</strong>`
            ),
          },
        ],
      })
      .start();
  }
}
