import { Component, Input, Renderer2 } from '@angular/core';
import { LinkedinConnectionsService } from 'src/app/services/linkedin-connections/linkedin-connections.service';
import { useLinkedinConnectionsStore } from 'src/app/store/linkedin-connections';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import {
  AnalyticsManagerService,
  Events,
} from '../services/analytics-manager.service';
import { ActivityMappingService } from '../services/activity-mapping.service';

@Component({
  selector: 'profile-cell',
  templateUrl: './profile-cell.component.html',
  styleUrls: ['./profile-cell.component.scss'],
})
export class ProfileCellComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() description: string;
  @Input() connectionId?: number;
  @Input() status?: string;
  @Input() stage?: string;
  @Input() outReachStatus?: string;
  @Input() dateAdded?: Date;
  @Input() connectedOn?: Date;
  @Input() lastActivityDate?: Date;
  @Input() link: string;
  @Input() sentiment?: string;
  @Input() company?: string;
  @Input() location?: string;
  @Input() email?: string;
  @Input() phone?: string;
  @Input() profileUrn?: string;
  @Input() numMessagesSent?: number;
  hasActivities: boolean;
  activityArray = [];
  menuLabels = ['View Activity', 'View Linkedin Profile'];
  arrowRightIcon = 'assets/icons/arrow-top-right.svg';

  constructor(
    private renderer: Renderer2,
    private linkedinConnectionService: LinkedinConnectionsService,
    private activityMappingService: ActivityMappingService
  ) { }

  setImage(url) {
    if (window.location.origin.includes('localhost')) {
      this.image = '';
      return;
    }
    let img = this.renderer.createElement('IMG');
    img.src = url;
    img.onerror = () => {
      this.image = '';
    };
  }

  onMenuClick(clickedLabel: string) {
    const connectionProfileData = [
      {
        connection_name: this.name,
        image_url: this.image,
        job_title: this.description,
        connected_on_date: this.connectedOn,
        created_at: this.dateAdded,
        sentiment: this.sentiment,
        profile_link: this.link,
        company_name: this.company,
        location: this.location,
        email: this.email,
        phone_number: this.phone,
        profile_urn: this.profileUrn,
        numMessagesSent: this.numMessagesSent,
        id: this.connectionId,
        status: this.status,
        stage: this.stage,
      },
    ];

    useLinkedinConnectionsStore.setState({
      activeConnection: connectionProfileData,
      isActivityLoading: true,
    });

    this.linkedinConnectionService
      .getLinkedinConnectionActivity(this.connectionId)
      .subscribe((activities: any) => {
        const activityArray = activities.map((activity) => {
          const config = this.activityMappingService.isActivityMappedById(
            activity
          )
            ? this.activityMappingService.resolveActivityMappingById(
              activity,
              this.name
            )
            : {
              activityType:
                this.activityMappingService.resolveActivityMessage(
                  activity.activity,
                  this.name
                ),
              message:
                !!activity.details &&
                  !this.activityMappingService.isPredefinedMessage(activity)
                  ? activity.details
                  : null,
              icon: this.activityMappingService.resolveActivityIcon(
                activity.activity
              ),
              createdAt: activity.created_at,
            };
          return config;
        });

        useLinkedinConnectionsStore.setState({
          connectionActivity: activityArray.filter(
            (activity) => activity !== undefined
          ),
          isActivityLoading: false,
        });
      });

    if (clickedLabel === 'View Activity') {
      AnalyticsManagerService.recordButtonClick(Events.activityLogClicked);
      useLinkedinConnectionsStore.setState({ connectionId: this.connectionId });
      useModalManagerStore.setState({ activityLog: true });
    } else if (clickedLabel === 'View Linkedin Profile') {
      AnalyticsManagerService.recordButtonClick(
        Events.viewLinkedinProfileClicked
      );
      window.open(this.link, '_blank');
    }
  }

  ngOnInit(): void {
    if (this.status === 'Connected') {
      this.menuLabels = ['View Activity', 'View Linkedin Profile'];
    } else {
      this.menuLabels = ['View Linkedin Profile'];
    }
    this.setImage(this.image);

    if (!this.name) this.name = 'N/A';
    if (!this.description) this.description = '---';
  }
}
