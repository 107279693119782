import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import * as fromRoot from '../../../store/app.reducer';
import * as AuthActions from '../../../auth/store/auth.actions';
import { UserState } from 'src/app/store/user/user.interface';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-account-menu',
  template: `
    <div
      class="account_menu_wrapper"
      (click)="toggleDropdown()"
      (clickOutside)="dropdownOpen = false"
    >
      <div class="account_menu_icon">
        <profile-image size="small" [image]="user.ClientLinkedinMetaDatum?.profile_image"  [fullName]="user.full_name"></profile-image>
      </div>
      <div class="{{ dropdownOpen ? 'dropdown_list' : 'drop_down_inactive' }}">
        <li class="menu-item user-name">{{ user.full_name }}</li>
        <li class="menu-item" (click)="navigateTo('user-profile')">
          Account Details
        </li>
        <li class="menu-item" (click)="onLogout()">Logout</li>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ClickOutsideDirective, SharedModule],
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  dropdownOpen: boolean = false;
  @Input() onNavClick: any;
  @Input() user: UserState;
  privacyUrl = 'https://pipa.cloud/privacy';
  termsUrl = 'https://pipa.cloud/terms';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.AppState>
  ) { }

  ngOnInit() {
    // console.log('user', this.user.ClientLinkedinMetaDatum?.profile_image);
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  navigateTo(location: string): void {
    this.router.navigate([`/${location}`], { relativeTo: this.route });
  }

  onLogout() {
    this.store.dispatch(AuthActions.logOut());
  }
}
